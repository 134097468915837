import React from 'react';
import styled from 'styled-components';
import { Step, Stepper, StepButton, StepContent } from 'material-ui/Stepper';
import { connect } from 'react-redux';
import ActionCreator from '../ActionCreator';
import Selectors from '../Selectors';


class TermsOfServiceContent extends React.Component {
  
  render() {
    return (
      <Wrapper>
        <h3>使用者條款</h3>
        <p>本條款與細則適用於所有GLOBALSTAR（以下簡稱本網站）瀏覽或使用本網站的客戶（以下簡稱“客戶”或“你”）。在瀏覽本網站之前，請客戶細心閱讀本條款與細則。</p>

        <h4>1. 協議</h4>
        <p>通過訪問或使用本網站，表示你確認你已經閱讀並理解下列的條款與細則，並同意這些條款，包括所有適用法律和法規的約束，並同意你有責任遵守本地任何適用的法律。如果你不同意當中任何條款，請避免訪問或使用本網站。在本網站所載的一切資料都受適用的版權和商標法律保護。</p>

        <h4>2. 客戶的義務</h4>
        <ol type="a">
          <li>對於我們提供的預付服務，客戶必須通過我們的網站GLOBALSTAR或其他與我們合作的平台購買我們的服務。</li>
          <li>客戶應向GLOBALSTAR提供正確和最新的個人信息，包括姓名和電郵地址。如果你的個人信息有任何更改，請馬上通過我們的網站或客戶服務熱線通知我們。</li>
          <li>客戶在台灣以外的地方使用我們的服務，可能會受到漫遊國家之法律和法規規定。客戶必須遵守這些法律和法規; 如客戶未能遵守那些法律和法規，GLOBALSTAR將不承擔任何責任。</li>
          <li>如果客戶到達目的地後，在使用產品和服務時遇到任何問題，請立即通知GLOBALSTAR，以便跟進。任何退款（全部或部份）要求，客戶需於服務生效日起計三十天內提出，過後恕不受理。GLOBALSTAR對任何退款要求保留最終酌情權。</li>
          <li> GLOBALSTAR建議客戶關閉手機自動下載軟件更新的功能，以避免不能預計的數據用量。</li>
        </ol>

        <h4>3. 會員行為</h4>
        <p>閣下清楚明白所有資訊、應用程式、或其他資料（以下統稱「內容」），不論是公開發放於本 網站及會員私下透過本網站傳輸，所有責任將由產生或發放該等內容之人士承擔，本公司不 會負上任何責任。因此，閣下必須為自己於本網站上載、登載或流通之一切內容負上全部責 任。本公司不能控制或核查利用本服務而上載至網上之內容，故此，本公司並不保證此等內 容準確及完整，亦不會對其質素作出保證。閣下清楚明白於使用本服務期間，有可能會接觸 到惹人反感或不安之內容。在任何情形下，本公司均不會對任何有關之內容，包括並不限於 任何錯誤或遺漏，或因任何人上載、登載、電郵或流通之內容所引致的損失或破壞負上任何 責任。</p>

        <h4>4. 授予的權利</h4>
        <ol type="a">
          <li>如你遵守本條款與細則，我們授予你訪問此網站的權利，讓你了解更多有關我們的產品或服務，並參與購買使用我們所提供的產品或服務。</li>
          <li>我們授予你從我們網站下載合理數量頁面作短暫儲存，或打印出合理數量頁面的權利，其目的是把我們產品或服務介紹給其他有興趣的人，前提是（一）你和獲得這些頁面副本的人，只可在准許的目的下使用這些資料，和（二）你必須保留這些資料上任何版權聲明或其他專利聲明，如我們在網站中展示的一樣。</li>
          <li>在我們授予你的權利之下，你不可對我們的資料（一）作出任何修改; （二）用於任何商業用途; （三）從中刪除任何版權或其他專利聲明。除了上述明確允許使用和打印本網站頁面的目的外，你不可為其他目的使用、複製、修改、分發、訪問我們網站，及其提供的任何資料。如果違反上述任何限制， 我們可在任何時間自動終止這授予的權利。這項權利的終止同時，你必須銷毀你所擁有任何資料，不論是電子或印刷格式，以及賠償我們的損失。</li>
        </ol>

        <h4>5. 產品及服務</h4>
        <p>本網站不收取任何入會費用 ，而對所有使用人提供免費及收費的選擇服務。閣下為了使用 免費或收費的下載服務而產生的有關的第三者費用，亦需由閣下全部負責，有關各項 收費詳情，請直接聯絡有關之電訊網絡商查詢。但本公司得斟酌就本服務收取之購買費用。 如本公司需更改收費時將不會預先通知閣下。
閣下明白及同意在使用由本網站下載或透過使用本網站或有關服務而獲得資訊，是自主決定 並應獨力承擔全部風險及責任。如下載任何資訊而導致閣下的電腦系統或資料有任何損失， 本公司絕對不承擔任何責任。GLOBALSTAR的流動數據漫遊服務可以在與我們有流動數據漫遊協議的電訊商之網絡上使用。GLOBALSTAR將不時更新網絡的覆蓋及數量，而不用另行書面通知。</p>

        <h4>6. 服務的限制</h4>
        <ol type="a">
          <li>GLOBALSTAR產品和服務只適合及限制在指定的國家或地區內使用。如果在指定的國家或地區外使用該產品和服務，而滋生任何費用，皆必須由客人負責。</li>
          <li>視頻下載，視頻串流，線上遊戲，視像通話等高數據使用量的活動可能會影響數據傳輸速度，其限制按照公平使用政策（FUP），將在第6點中描述。</li>
          <li>GLOBALSTAR的海外電訊服務供應商夥伴一般在當地內都有良好的覆蓋，但會因每個國家的供應商的系統而異（包括服務和系統超出了我們的控制範圍），特別是在偏遠地區。我們不保證服務的連續性和穩定性。</li>
          <li>網絡的可用性和覆蓋取決於客戶的位置。服務的質量取決於全部或部分在客戶的手機，流動裝置，網絡，及其他因素。</li>
          <li>服務可能會受到不利影響，原因有：如果同時有太多人嘗試使用網絡、物理因素（如建築物阻擋等）、和由大氣因素或其他原因的干擾。</li>
          <li>GLOBALSTAR不保證：連線不會中斷、數據的傳輸將在任何特定的速度或時間下進行、所有通訊可以或可能通過網絡傳輸、網絡會有不受未授權訪問或攔截的確實保護。 </li>
          <li>每次連線完畢，均結束閣下的帳號使用。閣下未能遵守本項規定所衍生之任何損失或 損害，本公司概不負任何責任。</li>
        </ol>

        <h4>7. 無限數據使用計劃的公平使用政策</h4>
        <ol type="a">
          <li>為了確保所有客戶在任何時候都獲得公正和公平的服務，按照當地的電訊服務供應商（國內本地無線網絡運營商）的商業行為，可能會實行公平使用政策（FUP）。</li>
          <li>如公平使用政策（FUP）實行，海外電訊服務供應商通常會對在一個特定時間內，使用超過一定用量數據的客戶，設置一個無線數據量限制。客戶可能在特定時間內被限制數據流量傳送，導致速度受限制（減慢）。</li>
          <li>一旦過量使用，電訊服務供應商可以在任何時間，停止，暫停或改變網絡的質量，我們將不作干預。在高峰期間（由於太多用戶同時使用網絡資源），你的流動數據體驗可能會間歇性地受到影響，一旦使用量恢復正常，情況將得到改善。</li>
          <li>電訊服務供應商有權改變服務模式和政策（包括公平使用政策），恕不另行通知。</li>
          <li>我們將不會因上述情況的發生而給予退款或折扣。如果客戶持續下載大量數據，或使用情況影響其他用戶的應用，我們可終止提供服務。 </li>
        </ol>

        <h4>8. 禁止事項</h4>
        <p>客戶禁止進行以下事項並須對使用我們產品和服務的行為作出負責。</p>
        <ol type="a">
          <li>干擾或威脅電訊服務供應商的網絡。</li>
          <li>以任何方式發送或上傳淫穢，攻擊，辱罵，威脅，騷擾的內容。</li>
          <li>侵犯他人的權利，包括版權或其他知識產權。</li>
          <li>發放任何會傷害或損壞網絡設施或服務，或干擾安全功能正常操作的工具。</li>
          <li>發放未經請求的的商業信息或內容。</li>
          <li>進行任何可能是非法，欺詐，不正當，未經許可，騷擾，歧視，誹謗，辱罵，威脅，有害，粗俗，淫穢或其他不良目的的行為。</li>
          <li>進行任何可能破壞GLOBALSTAR或GLOBALSTAR聲譽的行為。</li>
          <li>進行可能會鼓勵刑事罪行，民事法律責任，或任何其他違反相關法律的行為。</li>
          <li>進行可能變換，分解，破壞，改變設定，或者引起其他設備故障或改變產品外觀的行為。</li>
          <li>進行轉租，轉讓，向第三方抵押或其他違反GLOBALSTAR擁有權的行為。</li>
        </ol>

        <h4>9.更換和退款</h4>
        <ol type="a">
          <li>如果需要更換GLOBALSTAR卡，客戶必須致電我們的客戶服務熱線。</li>
          <li>如果GLOBALSTAR卡已損壞（並非因GLOBALSTAR的錯誤）而客戶要求更換另一張GLOBALSTAR卡，你將需要支付更換GLOBALSTAR卡的費用及可能產生的郵費或任何其他費用。</li>
          <li>如客戶因任何原因不能使用數據服務，請致電我們客戶服務熱線，或經由任何客服管道聯繫我們的客服專員，以便安排全數退款。任何退款要求，客戶需於服務生效日起三十天內提出，過後恕不受理。</li>
        </ol>

        <h4>10. 收集個人資料聲明</h4>
        <ol>
          <li>客戶必須確保提供給GLOBALSTAR的個人信息是最新，完整和準確。</li>
          <li>遵從個人資料（私隱）條例的規定，GLOBALSTAR將遵守條例的義務和規定，妥善處理和管理客戶的個人資料。 </li>
        </ol>

        <h4>11. 條款與細則的修訂</h4>
        <ol type="a">
        <li>GLOBALSTAR保留在不予事先通知下隨時修改這些條款與細則的權利。一旦發生糾紛，GLOBALSTAR保留最終決定權。</li>
        <li>GLOBALSTAR保留拒絕提供產品和服務的權利。GLOBALSTAR所提供的產品和服務的都必須遵守本條款與細則，並在進行購買之前得到客戶的讀取和完全同意。</li>
        <li>GLOBALSTAR可以在任何時候對網站，和／或GLOBALSTAR的產品和服務，進行必要的維護，以確保所有客戶得到的服務質量。</li>
        </ol>

        <h4>12. 免責聲明</h4>
        <ol type="a">
          <li>GLOBALSTAR將不會對海外電訊服務供應商的錯誤而造成的任何損害和損失承擔責任。</li>
          <li>GLOBALSTAR將不會因客戶的流動裝置故障和／或設定錯誤導致未能獲得互聯網服務負上責任。</li>
          <li>GLOBALSTAR不會對客戶不適當、不小心使用、或誤置流動裝置導致短路或火災，引致的人員傷亡和損失承擔任何責任，GLOBALSTAR建議客戶留意和小心使用裝置。</li>
          <li>客戶同意如果GLOBALSTAR卡丟失，被盜或損毀，或沒有你的授權下被使用，我們沒有責任對你帳戶的餘額作出退款。</li>
          <li>GLOBALSTAR可在沒有另行通知下，對全部或部分服務作出我們認為需要的修改或暫停，（例如，維護，升級，安全，緊急或其他理由）。我們會努力盡量減少這種服務中斷。然而，有些中斷是不可避免的。如這情況出現及在可行之下，我們會盡快通知客戶。</li>
          <li>GLOBALSTAR可能不時改變產品和服務，以遵守適用的安全、法規或法定要求，恕不另行通知。但這種變化不會對產品和服務的性質和範圍、收費有重大影響。</li>
          <li>GLOBALSTAR並不聲明及保證產品本身，和服務的運行（或包括第三方提供的相關產品或服務）不會中斷、適時的、安全或正確無誤，或可滿足客戶的特定需求。</li>
          <li>在超出我們可控制情況下，產品和服務的質量和供應會受到一定的限制，包括但不限於物理、地理和氣候因素。</li>
        </ol>
      </Wrapper>
    );
  }
}
const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  padding: 50px;

  & > h3 {
    padding: 20px 0px;
    color: #5a5a5a;
  }

  & > h4 {
    padding: 20px 0px;
    color: #5a5a5a;
  }

  & > p, li {
    color: #6b6b6b;
    font-size: 15px;
    line-height: 30px;
    list-style-position: inside;
  }

  
`;

export default connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
  }),
  ActionCreator
)(TermsOfServiceContent);
