import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withPage } from '../../plugin/Page';
import Banner from '../Components/Banner';
import SpTermsOfServiceContent from '../Components/SpTermsOfServiceContent';

class TermsOfServicePage extends React.Component {
  render() {
    let { lang } = this.props;
    return (
      <Wrapper>
        <SpTermsOfServiceContent />
      </Wrapper>
    );
  }
}
const Wrapper = styled.div`
  padding: 20px 0;
  background-color: #ededed;

  ${props => props.extraCss || ''};
`;

export default withPage(TermsOfServicePage);
